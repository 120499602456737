import React, { useState } from "react";
import "./DentalRequestForm.css";
import emailjs from "emailjs-com";

const DentalRequestForm = () => {
  const emailJsServiceId = "service_vcapj5s";
  const emailJsUserId = "G-sWaa7kcmFltPO3k";

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    requestorName: "",
    dentalPracticeName: "",
    practiceAddress: "",
    email: "",
    phoneNumber: "",
    requestType: "Dental Assistant Nurse",
    startDateTime: "",
    endDateTime: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onEmailSent = () => {
    setLoading(false);
    setFormData({
      requestorName: "",
      dentalPracticeName: "",
      practiceAddress: "",
      email: "",
      phoneNumber: "",
      requestType: "Dental Assistant Nurse",
      startDateTime: "",
      endDateTime: "",
      message: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const form = e.target;

    emailjs
      .sendForm(emailJsServiceId, "template_6fh3iku", form, emailJsUserId)
      .then(
        (response) => {
          onEmailSent();
          alert("Request sent successfully!");
          console.log("SUCCESS!", response.status, response.text);
        },
        (error) => {
          setLoading(false);
          alert("Failed to send request. Please try again later.");
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <form onSubmit={handleSubmit} className="container requestor-form">
      <div className="form-group">
        <label htmlFor="requestorName">Name of Requestor:</label>
        <input
          type="text"
          id="requestorName"
          name="requestorName"
          value={formData.requestorName}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="dentalPracticeName">Name of Dental Practice:</label>
        <input
          type="text"
          id="dentalPracticeName"
          name="dentalPracticeName"
          value={formData.dentalPracticeName}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="practiceAddress">Address of Dental Practice:</label>
        <input
          type="text"
          id="practiceAddress"
          name="practiceAddress"
          value={formData.practiceAddress}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number:</label>
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>

      <div className="form-group">
        <label>Type of Request:</label>
        <div className="form-check">
          <input
            type="radio"
            id="requestType1"
            name="requestType"
            value="Dental Assistant Nurse"
            checked={formData.requestType === "Dental Assistant Nurse"}
            onChange={handleChange}
            className="form-check-input"
          />
          <label className="form-check-label" htmlFor="requestType1">
            Dental Assistant Nurse
          </label>
        </div>
        <div className="form-check">
          <input
            type="radio"
            id="requestType2"
            name="requestType"
            value="Reception Staff"
            checked={formData.requestType === "Reception Staff"}
            onChange={handleChange}
            className="form-check-input"
          />
          <label className="form-check-label" htmlFor="requestType2">
            Reception Staff
          </label>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="startDateTime">Start Date Time:</label>
        <input
          type="datetime-local"
          id="startDateTime"
          name="startDateTime"
          value={formData.startDateTime}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="endDateTime">End Date Time:</label>
        <input
          type="datetime-local"
          id="endDateTime"
          name="endDateTime"
          value={formData.endDateTime}
          onChange={handleChange}
          className="form-control"
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="message">Message</label>
        <textarea
          type="text"
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          className="form-control"
        />
      </div>

      <div class="text-center">
        {/* <button type="submit" className="btn btn-primary btn-xl">
          Submit Request
        </button> */}
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? "Submitting..." : "Submit Request"}
        </button>
      </div>
    </form>
  );
};

export default DentalRequestForm;
