import "./App.css";

import jhanviImg from "./assets/img/jhanvi.jpeg";
import krisImg from "./assets/img/kris.jpeg";
import nurseImg from "./assets/img/nurse.png";
import receptionistImg from "./assets/img/receptionist.png";
import DentalRequestForm from "./DentalRequestForm";

function App() {
  return (
    <div className="App">
      {/* <!-- Header--> */}
      <header class="masthead d-flex align-items-center">
        <div class="container px-4 px-lg-5 text-center">
          <h1 class="mb-1">Welcome to Flexicrew</h1>
          <h3 class="mb-5">
            <em>Matching Great Practices with Great People</em>
          </h3>
          <a class="btn btn-primary btn-xl" href="#about">
            Find Out More
          </a>
        </div>
      </header>
      {/* <!-- About--> */}
      <section class="content-section background" id="about">
        <div class="container shadow-container px-4 px-lg-5">
          <div class="row content-section-heading gx-4 gx-lg-5 justify-content-center">
            <div class="col-lg-10">
              <h2 class="text-center">Our Mission</h2>
              <p class="lead mb-5">
                At FlexiCrew, our mission is to connect dental practices with
                highly skilled and compassionate temporary dental nurses and
                receptionists through a cutting-edge, user-friendly platform. We
                are committed to ensuring that every dental office can operate
                seamlessly and continue to provide exceptional patient care,
                even in the absence of regular staff. Our innovative marketplace
                offers reliable, flexible, and prompt staffing solutions
                tailored to the unique needs of each practice. By leveraging
                advanced technology, we streamline the process of finding and
                booking temporary dental nurses, making it easier than ever for
                dental practices to maintain high standards of care. We believe
                in fostering a supportive environment where dental nurses can
                thrive and grow in their careers, ultimately enhancing the
                quality of dental care for all.
              </p>
              {/* <a class="btn btn-light btn-xl" href="#services">
                What We Offer
              </a> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Services--> */}
      <section
        class="content-section bg-primary text-white text-center"
        id="services"
      >
        <div class="container px-4 px-lg-5">
          <div class="content-section-heading">
            <h3 class="text-secondary mb-0">Services</h3>
            <h2 class="mb-5">What We Offer</h2>
            <div class="row gx-4 gx-lg-5">
              <div class="col-lg-6 col-md-6 mb-5 mb-lg-0 text-center">
                <img alt="" src={nurseImg}></img>
                <h4>
                  <strong>Dental Assistant Nurse</strong>
                </h4>
                <p class="text-faded mb-0"></p>
              </div>
              <div class="col-lg-6 col-md-6 mb-5 mb-lg-0 text-center">
                <img alt="" src={receptionistImg}></img>

                <h4>
                  <strong>Reception Staff</strong>
                </h4>
                <p class="text-faded mb-0"></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Portfolio--> */}
      <section class="content-section" id="portfolio">
        <div class="container px-4 px-lg-5">
          <div class="content-section-heading text-center">
            {/* <h3 class="text-secondary mb-0">Portfolio</h3> */}
            <h2 class="mb-5">Who We Are</h2>
          </div>
          <div class="row gx-0">
            <div class="col-lg-6">
              <a class="portfolio-item" href="#!">
                <div class="caption">
                  <div class="caption-content">
                    <div class="h2">Dr. Jhanvi Kotecha</div>
                    <p class="mb-0">
                      As an active member of the Australian Dental Association
                      and registered with the Dental Board of Australia and
                      AHPRA, Dr. Jhanvi upholds the highest standards of
                      professionalism and patient care.
                    </p>
                  </div>
                </div>
                <img class="img-fluid" src={jhanviImg} alt="..." />
              </a>
            </div>
            <div class="col-lg-6">
              <a class="portfolio-item" href="#!">
                <div class="caption">
                  <div class="caption-content">
                    <div class="h2">Dr. Kris Thyer</div>
                    <p class="mb-0">
                      Dr. Kris Thyer graduated from The University of Western
                      Australia in 2011 and has been practising dentistry full
                      time in private practice since then. Dr. Kris is
                      passionate about all aspects of general dentistry and
                      enjoys providing quality treatment and care to patients in
                      a comfortable, relaxed and caring environment.
                    </p>
                  </div>
                </div>
                <img class="img-fluid" src={krisImg} alt="..." />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section class="content-section bg-primary text-white">
        <div class="container px-4 px-lg-5">
          <div class="content-section-heading text-center">
            {/* <h3 class="text-secondary mb-0">Portfolio</h3> */}
            <h2 class="mb-5">Get In Touch</h2>
          </div>
          <div class="row gx-0">
            <div>
              <DentalRequestForm></DentalRequestForm>
            </div>
          </div>
        </div>
      </section>
      <div class="map">
        <iframe
          title="map"
          width="100%"
          height="600"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=24%20Hasler%20Road,%20Osbourne%20Park,%20WA,%206017+(Flexicrew)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/">gps vehicle tracker</a>
        </iframe>
      </div>

      {/* <!-- Footer--> */}
      <footer class="footer text-center bg-dark">
        <div class="container px-4 px-lg-5">
          {/* <ul class="list-inline mb-5">
            <li class="list-inline-item">
              <a class="social-link rounded-circle text-white mr-3" href="#!">
                <i class="icon-social-facebook"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="social-link rounded-circle text-white mr-3" href="#!">
                <i class="icon-social-twitter"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="social-link rounded-circle text-white" href="#!">
                <i class="icon-social-github"></i>
              </a>
            </li>
          </ul> */}
          <p class="text-white small mb-0">Copyright &copy; Flexicrew 2024</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
